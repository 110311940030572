const initialState = {
  isShowModal: false
}

const TOGGLE_SHOW_MODAL = 'TOGGLE_SHOW_MODAL'

export const toggleShowModal = () => ({
  type: TOGGLE_SHOW_MODAL
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SHOW_MODAL:
      return { ...state, isShowModal: !state.isShowModal }
    default:
      return state
  }
};
